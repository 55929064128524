<template>
  <section>
    <div class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Doppelrollo <br />
    </div>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h1 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Germania - Doppelrollo
      </h1>
      <div class="grid grid-cols-2">
        <div class="col-span-full lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>

        <div class="col-span-full lg:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Privatsphäre und Lichtregulierungmit unverwechselbarem Stil
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Gutes Design ist einfach, gut gestaltete Produkte lassen sich
            einfach bedienen. Wir haben sie gern um uns, und sie bleiben immer
            „best friends“. Seit mehr als 60 Jahren wird das Design der
            Fensterbehänge von Luxaflex® weiterentwickelt, um jedes Zuhause
            vollkommen und einzigartig zu gestalten.<br /><br />
            Das Twist® Doppelrollo ist ein perfektes Beispiel dafür. Die Twist®
            Stoffe sind abwechselnd in dichten und trans-parenten Streifen
            gewebt. Je nach Über-lagerung der unterschiedlichen Segmente wird
            eine stufenlose Regulierung des Lichteinfalls möglich. Das
            Verschieben und die Dopplung der Stoffstreifen erzeugt so große und
            kleine Einblicke und Ausblicke. Das Twist® Doppelrollo ist
            raffiniert, elegant, zeitgemäß und einfach in der Handhabung.
          </p>
        </div>
        <div class="col-span-full">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Einfache Bedienung, edles Design
          </h2>
          <p class="pl-5 w-full md:pl-10 md:pb-3">
            Das Maß an Transparenz und Lichteinfall lässt sich mühelos über die
            Kettenbedienung einstellen. Sie können das Rollo auch ganz nach oben
            ziehen. Optional ist auch eine motorisierte Bedienung möglich. Die
            Unterschiene aus Aluminium hat runde, ebenfalls aus Aluminium
            bestehende Verschlusskappen. Standard ist ein Wand-montagesystem,
            aber optional ist auch die Befestigung an der Decke kein Problem.
            <br /><br />
            Das Twist® Doppelrollo ist ein perfektes Beispiel dafür. Die Twist®
            Stoffe sind abwechselnd in dichten und trans-parenten Streifen
            gewebt. Je nach Über-lagerung der unterschiedlichen Segmente wird
            eine stufenlose Regulierung des Lichteinfalls möglich. Das
            Verschieben und die Dopplung der Stoffstreifen erzeugt so große und
            kleine Einblicke und Ausblicke. Das Twist® Doppelrollo ist
            raffiniert, elegant, zeitgemäß und einfach in der Handhabung.
          </p>
        </div>
      </div>
      <div class="p-4">
        <div class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</div>
        <tabs :mode="mode">
          <tab title="Powerview">
            <p class="text-lg">
              <span class="font-bold">Motorisierung</span> <br />
              Stellen Sie sich vor, Ihr Sonnenschutz könnte Ihren Bedürfnissen
              zuvorkommen und sich Ihnen automatisch anpassen. Die perfekte
              Raumatmosphäre am Morgen, Mittag und Abend! All dies bietet Ihnen
              die PowerView® Motorisierung. Sie müssen PowerView® lediglich so
              programmieren, dass es die Luxaflex®-Anlagen in Ihrem Zuhause
              steuern kann. Nutzen Sie dazu die PowerView® App auf Ihrem
              Smartphone oder Tablet. Weitere Informationen finden Sie in der
              PowerView®-Kollektion.
            </p>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              Germania - Doppelrollo ist in verschiedenen Farben und
              Ausführungen erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-2.webp"),
          alt: "doppelrollo germania wohnzimmer",
        },
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-3.webp"),
          alt: "doppelrollo kueche",
        },
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-7.webp"),
          alt: "doppelrollo nahaufnahme",
        },
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-12.webp"),
          alt: "doppelrollo fuer mehr ambiente",
        },
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-18.webp"),
          alt: "doppelrollo im buero",
        },
        {
          image: require("@/assets/img/germania/doppelrollo/Duosoft-25.webp"),
          alt: "doppelrollo in wartezimmer",
        },
      ],
    };
  },
};
</script>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
